@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes progress-bar {
  0% {
    stroke-dashoffset: 87.9646; /* full circumference, 2 * pi * r */
  }
  100% {
    stroke-dashoffset: 0; /* Complete the circle */
  }
}

h1 {
  font-family: 'Pacifico', sans-serif !important;
}


@keyframes windUpAndSpin {
  0% {
    transform: rotate(0deg); /* Start at initial position */
  }
  30% {
    transform: rotate(-30deg); /* Wind up counterclockwise */
  }
  70% {
    transform: rotate(0deg); /* Return to start */
  }
  100% {
    transform: rotate(360deg); /* Spin 360 clockwise */
  }
}


@keyframes pulseLeft {
  0% {
    transform: rotate(0deg)
  }
  5% {
    transform: rotate(20deg)
  }
  10% {
    transform: rotate(-20deg)
  }
  15% {
    transform: rotate(20deg)
  }
  20% {
    transform: rotate(-20deg)
  }
  25% {
    transform: rotate(20deg)
  }
  30% {
    transform: rotate(0deg)
  }
  35% {
    transform: rotate(0deg)
  }
}

.icon-spin {
  animation: windUpAndSpin 2s ease-in-out infinite; /* Loop continuously */
}

.icon-pulse-left {
  animation: pulseLeft 4s ease-in-out infinite; /* Loop continuously */
}

.bg-offwhite {
  background-color: #979797;
}
